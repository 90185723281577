/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

// Register $ global var for jQuery
global.$ = global.jQuery = require('jquery');

// Import jQuery Plugins
import autocomplete from "jquery-ui/ui/widgets/autocomplete";
global.bootbox = require('bootbox');

const Dropzone = require('dropzone');
require( 'summernote' );
require( 'summernote-cleaner' )
require( 'bootstrap-star-rating' );
require( 'smoothscroll' );
require( 'jquery-cropper' );
require('noty');

/*-------------------------------------
Setup AJAX to work with CSRF
-------------------------------------*/
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/*-------------------------------------
Quantity Holder
-------------------------------------*/
$('#quantity-holder').on('click', '.quantity-plus', function () {

    var $holder = $(this).parents('.quantity-holder');
    var $target = $holder.find('input.quantity-input');
    var $quantity = parseInt($target.val(), 10);
    if ($.isNumeric($quantity) && $quantity > 0) {
        $quantity = $quantity + 1;
        $target.val($quantity);
    } else {
        $target.val($quantity);
    }

}).on('click', '.quantity-minus', function () {

    var $holder = $(this).parents('.quantity-holder');
    var $target = $holder.find('input.quantity-input');
    var $quantity = parseInt($target.val(), 10);
    if ($.isNumeric($quantity) && $quantity >= 2) {
        $quantity = $quantity - 1;
        $target.val($quantity);
    } else {
        $target.val(1);
    }
});

/*-------------------------------------
On Scroll
-------------------------------------*/
$(window).on('scroll', function () {
    if ($(window).scrollTop() > 700) {
        $('.scrollup').addClass('back-top');
    } else {
        $('.scrollup').removeClass('back-top');
    }
});

/*-------------------------------------
Active Menu
-------------------------------------*/
$('#site-menu li a').on('click', function () {
    $('#site-menu').find('.current').removeClass('current');
    $(this).parent().addClass('current');
});


$('.toggle-menu').on('click', function () {
    $('#site-menu').slideToggle(500);
    $(this).toggleClass('active');
})

/*-------------------------------------
Menu fixded
-------------------------------------*/
if ($('header .header-main-menu').length && $('header .header-main-menu').hasClass('header-sticky')) {
    var header_position = $('header .header-main-menu').offset(),
        lastScroll = 100;
    $(window).on('scroll load', function (event) {
        var st = $(this).scrollTop();
        if (st > header_position.top) {
            ($(".header-table").length) ? $('header .header-table').addClass("header-fixed") : $('header .header-main-menu').addClass("header-fixed");
        } else {
            ($(".header-table").length) ? $('header .header-table').removeClass("header-fixed") : $('header .header-main-menu').removeClass("header-fixed");
        }

        lastScroll = st;

        if (st === 0) {
            ($(".header-table").length) ? $('header .header-table').removeClass("header-fixed") : $('header .header-main-menu').removeClass("header-fixed");
        }
    });
}

/*-------------------------------------
Window On Load Function
-------------------------------------*/
$(window).on('load', function () {

    // Page Preloader
    $('#preloader').fadeOut('slow', function () {
        $(this).remove();
    });

});

/*---------------------------------------
Summernote
--------------------------------------- */
if ($.fn.summernote !== undefined) {
    $(document).ready(function () {
        $('.summernote').summernote({
            height: 300,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'video', 'table']],
                ['view', ['codeview']]
            ],
            cleaner: {
                action: 'paste',
                keepHtml: true,
                keepOnlyTags: ['<p>', '<br>', '<ul>', '<ol>', '<li>', '<b>', '<strong>','<i>', '<a>']
            }
        });
    });
}

/*---------------------------------------
Dropzone
--------------------------------------- */
Dropzone.autoDiscover = false;
